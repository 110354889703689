import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import App from '../App';
import Hero from '../components/Hero';
import WorkingWithUsSection from '../components/WorkingWithUsSection';
import SmallQuotationSection from '../components/QuotationSection/SmallQuotationSection';
import StreambrightInNumbers from '../components/StreambrightInNumbers';
import BuildWithExperienceSection from '../components/BuildWithExperienceSection';
import Button from '../components/Button';
import GreenSquareIcon from '../assets/icons/GreenSquareIcon';
import TeamPageHeroImage from '../gatsbyImages/TeamPageHeroImage';
import FirstQuoteBackgroundImage from '../gatsbyImages/FirstQuoteBackgroundImage';
import SecondQuoteBackgroundImage from '../gatsbyImages/SecondQuoteBackgroundImage';
import ThirdQuoteBackgroundImage from '../gatsbyImages/ThirdQuoteBackgroundImage';
import adamJermann from '../assets/images/adam-jermann-avatar.png';
import danielGergely from '../assets/images/daniel-gergely-avatar.png';
import davidProkaj from '../assets/images/david-prokaj-avatar.png';

const messages = defineMessages({
  title: {
    id: 'teamPage.title',
    defaultMessage: 'Our Knowledge Base Is {br} Your Secret Weapon'
  },
  description: {
    id: 'teamPage.description',
    defaultMessage:
      'The reason we wake up excited to build products is the same reason you can {br} expect exceptional software delivered on time. '
  },
  firstQuoteText: {
    id: 'teamPage.firstQuoteText',
    defaultMessage:
      'Our dev team isn’t just a bunch of programmers, they’re developers. Programmers do what they are told to do, developers think and try to make something better along the way.'
  },
  secondQuoteText: {
    id: 'teamPage.secondQuoteText',
    defaultMessage: `With every project, we get to try out all of these new technologies and libraries. What we're excited about is how these technologies can integrate together. And so when we use that technology in one of the projects, then obviously that knowledge is going to be shared with the whole team.`
  },
  thirdQuoteText: {
    id: 'teamPage.thirdQuoteText',
    defaultMessage:
      'Everyone on our team gets to work where they’re the most productive. For some that’s in our awesome downtown HQ, while for others great things happen in their own private office. Our flexibility is our advantage.'
  },
  workingWithUsSectionTitle: {
    id: 'teamPage.workingWithUsSectionTitle',
    defaultMessage: 'Working with us'
  },
  workingWithUsSectionDescription: {
    id: 'teamPage.workingWithUsSectionDescription',
    defaultMessage:
      'Are you into growth mindset, professional excellence and workplace flexibility? {br} Then you may be into working with us.'
  },
  workingWithUsSectionButton: {
    id: 'teamPage.workingWithUsSectionButton',
    defaultMessage: 'Send us your CV'
  }
});

function TeamPage() {
  return (
    <App>
      <Helmet>
        <title>Streambright - Our Team is Your Secret Weapon</title>
        <meta
          name="description"
          content="The reason we wake up excited to build products is the same reason you can expect exceptional software delivered on time."
        />
      </Helmet>
      <Hero
        title={<FormattedMessage {...messages.title} values={{ br: <br /> }} />}
        description={
          <FormattedMessage {...messages.description} values={{ br: <br /> }} />
        }
        icon={<GreenSquareIcon />}
        backgroundImage={TeamPageHeroImage}
      />
      <SmallQuotationSection
        backgroundImage={FirstQuoteBackgroundImage}
        description={messages.firstQuoteText}
        avatar={<img src={danielGergely} alt="Daniel Gergely" />}
        authorName="Daniel Gergely"
        jobTitle="CTO"
      />
      <StreambrightInNumbers />
      <SmallQuotationSection
        backgroundImage={SecondQuoteBackgroundImage}
        description={messages.secondQuoteText}
        avatar={<img src={davidProkaj} alt="David Prokaj" />}
        authorName="David Prokaj"
        jobTitle="Lead Frontend Developer"
        reverse
      />
      <BuildWithExperienceSection />
      <SmallQuotationSection
        backgroundImage={ThirdQuoteBackgroundImage}
        description={messages.thirdQuoteText}
        avatar={<img src={adamJermann} alt="Adam Jermann" />}
        authorName="Adam Jermann"
        jobTitle="CEO"
      />
      <div id="Contact">
        <WorkingWithUsSection
          title={messages.workingWithUsSectionTitle}
          description={
            <FormattedMessage
              {...messages.workingWithUsSectionDescription}
              values={{ br: <br /> }}
            />
          }
        >
          <a
            href="https://streambright.typeform.com/to/BuXxMy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" color="primary">
              <FormattedMessage {...messages.workingWithUsSectionButton} />
            </Button>
          </a>
        </WorkingWithUsSection>
      </div>
    </App>
  );
}

export default TeamPage;
