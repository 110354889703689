import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const FirstQuoteBackgroundImage = props => {
  const data = useStaticQuery(graphql`
    query {
      firstQuoteBackgroundImage: file(
        relativePath: { eq: "first-quote-background.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img
      fluid={data.firstQuoteBackgroundImage.childImageSharp.fluid}
      {...props}
    />
  );
};

export default FirstQuoteBackgroundImage;
