import React from 'react';

function CloudIcon() {
  return (
    <svg
      width="32px"
      height="22px"
      viewBox="0 0 32 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-407.000000, -3126.000000)" stroke="#157EFB" strokeWidth="2.4000001">
          <g transform="translate(80.000000, 2618.000000)">
            <g transform="translate(329.000000, 509.000000)">
              <path
                d="M24.2206816,8.02358125 C24.1231469,3.83034063 20.7068878,0.46063125 16.5037224,0.46063125 C13.5860265,0.46063125 11.0470469,2.0845375 9.73387959,4.4802 C9.14068163,4.1382 8.45355918,3.94160938 7.72021429,3.94160938 C5.58599184,3.94160938 3.83871224,5.59888438 3.68418367,7.70040313 C1.57878367,8.56763438 0.095522449,10.6439781 0.095522449,13.0687938 C0.095522449,16.2731438 2.68474898,18.8708 5.87883265,18.8708 L23.2764653,18.8708 C26.2851265,18.8708 28.7241449,16.4238969 28.7241449,13.4055094 C28.7241449,10.7102406 26.7790143,8.47239688 24.2206816,8.02358125 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}

export default CloudIcon;