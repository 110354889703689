import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Container from '../Container';
import Quotation from './Quotation';
import styles from './QuotationSection.module.scss';

function SmallQuotationSection(props) {
  const { backgroundImage: BackgroundImage, ...rest } = props;

  return (
    <Container className={styles.smallQuotationSectionContainer}>
      <BackgroundImage
        className={styles.backgroundImage}
        style={{ position: 'absolute' }}
        critical
        fadeIn={false}
      />
      <Quotation {...rest} />
    </Container>
  );
}

SmallQuotationSection.propTypes = {
  backgroundImage: PropTypes.node.isRequired
};

export default injectIntl(SmallQuotationSection);
