import React from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import Button from '../Button';

const Link = ({ to, ...rest }) => {
  const handleClick = () => {
    navigate(to);
  };

  return <Button onClick={handleClick} {...rest} />;
};

Link.propTypes = {
  to: PropTypes.string.isRequired
};

export default Link;
