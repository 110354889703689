import React from 'react';

function SecurityIcon() {
  return (
    <svg
      width="33px"
      height="38px"
      viewBox="0 0 33 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-853.000000, -2980.000000)" stroke="#373737" strokeWidth="1.43999996">
          <g transform="translate(854.000000, 2981.000000)">
            <rect x="0" y="13.5" width="31" height="22.5" />
            <rect x="13.6764706" y="24.3" width="4.55882353" height="7.2" />
            <path
              d="M12.2205881,0 L18.7794119,0 C22.5073339,-6.84808155e-16 25.5294118,3.02207786 25.5294118,6.74999982 L25.5294118,13.5 L5.47058824,13.5 L5.47058824,6.74999982 C5.47058824,3.02207786 8.49266609,6.84808155e-16 12.2205881,0 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SecurityIcon;