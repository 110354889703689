import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Container from '../Container';
import HortonworksIcon from '../../assets/icons/HortonworksIcon';
import ClouderaIcon from '../../assets/icons/ClouderaIcon';
import AwsIcon from '../../assets/icons/AwsIcon';
import BlueDotsIcon from '../../assets/icons/BlueDotsIcon';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import styles from './StreambrightInNumbers.module.scss';

const messages = defineMessages({
  mainTitle: {
    id: 'streambrightInNumbers.mainTitle',
    defaultMessage: 'StreamBright In Numbers'
  },
  number1: {
    id: 'streambrightInNumbers.number1',
    defaultMessage: '9'
  },
  title1: {
    id: 'streambrightInNumbers.title1',
    defaultMessage: 'projects'
  },
  description1: {
    id: 'streambrightInNumbers.description1',
    defaultMessage: 'delivered Within 710 Days'
  },
  number2: {
    id: 'streambrightInNumbers.number2',
    defaultMessage: '6'
  },
  title2: {
    id: 'streambrightInNumbers.title2',
    defaultMessage: 'senior developers'
  },
  description2: {
    id: 'streambrightInNumbers.description2',
    defaultMessage: 'With a holistic, interdisciplinary approach'
  },
  number3: {
    id: 'streambrightInNumbers.number3',
    defaultMessage: '2'
  },
  title3: {
    id: 'streambrightInNumbers.title3',
    defaultMessage: 'medior developers'
  },
  description3: {
    id: 'streambrightInNumbers.description3',
    defaultMessage: 'With the resources to solve complex challenges'
  },
  number4: {
    id: 'streambrightInNumbers.number4',
    defaultMessage: '420'
  },
  title4: {
    id: 'streambrightInNumbers.title4',
    defaultMessage: 'full-time equivalent'
  },
  description4: {
    id: 'streambrightInNumbers.description4',
    defaultMessage: 'saved by providing the most practical solutions possible'
  },
  number5: {
    id: 'streambrightInNumbers.number5',
    defaultMessage: '100%'
  },
  title5: {
    id: 'streambrightInNumbers.title5',
    defaultMessage: 'client- recommended'
  },
  description5: {
    id: 'streambrightInNumbers.description5',
    defaultMessage: 'all of our clients have referred us to partners'
  },
  number6: {
    id: 'streambrightInNumbers.number6',
    defaultMessage: '6'
  },
  title6: {
    id: 'streambrightInNumbers.title6',
    defaultMessage: 'months'
  },
  description6: {
    id: 'streambrightInNumbers.description6',
    defaultMessage: 'average software dev project length'
  },
  subTitle: {
    id: 'streambrightInNumbers.subTitle',
    defaultMessage:
      'Our developers helped develop the services and tools we build with:'
  }
});

function StreambrightInNumbers() {
  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>
        <FormattedMessage {...messages.mainTitle} />
      </div>
      <Container className={styles.contentContainer}>
        <div>
          <div className={styles.number}>
            <FormattedMessage {...messages.number1} />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title1} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description1} />
          </div>
        </div>
        <div>
          <div className={styles.number}>
            <FormattedMessage {...messages.number2} />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title2} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description2} />
          </div>
        </div>
        <div>
          <div className={styles.number}>
            <FormattedMessage {...messages.number3} />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title3} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description3} />
          </div>
        </div>
        <div>
          <div className={styles.number}>
            <FormattedMessage {...messages.number4} />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title4} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description4} />
          </div>
        </div>
        <div>
          <div className={styles.number}>
            <FormattedMessage {...messages.number5} />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title5} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description5} />
          </div>
        </div>
        <div>
          <div className={styles.number}>
            <FormattedMessage {...messages.number6} />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title6} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description6} />
          </div>
        </div>
      </Container>
      <div className={styles.subTitle}>
        <FormattedMessage {...messages.subTitle} />
      </div>
      <div className={styles.iconContainer}>
        <HortonworksIcon />
        <ClouderaIcon />
        <AwsIcon />
      </div>
      <div className={styles.blueIcon}>
        <BlueDotsIcon />
      </div>
      <div className={styles.greenIcon}>
        <GreenSquareIcon />
      </div>
    </div>
  );
}

export default StreambrightInNumbers;
