import React from 'react';

function LogisticIcon() {
  return (
    <svg
      width="52px"
      height="32px"
      viewBox="0 0 52 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-842.000000, -2839.000000)" stroke="#373737" strokeWidth="1.27999992">
          <g transform="translate(843.000000, 2840.000000)">
            <rect x="0" y="0" width="35.483871" height="25.9459459" />
            <polygon
              points="35.483871 6.48648649 45.9659384 6.48648649 50 12.1888936 50 25.9459459 35.483871 25.9459459" />
            <ellipse fill="#FFFFFF" cx="42.7419355" cy="25.9459459" rx="4.03225806"
                     ry="4.05405405" />
            <ellipse fill="#FFFFFF" cx="9.67741935" cy="25.9459459" rx="4.03225806"
                     ry="4.05405405" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogisticIcon;