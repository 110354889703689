import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Container from '../Container';
import Link from '../Link';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import ReactIcon from '../../assets/icons/ReactIcon';
import JavaIcon from '../../assets/icons/JavaIcon';
import NodejsIcon from '../../assets/icons/NodejsIcon';
import CloudIcon from '../../assets/icons/CloudIcon';
import LogisticIcon from '../../assets/icons/LogisticIcon';
import FintechIcon from '../../assets/icons/FintechIcon';
import EcommerceIcon from '../../assets/icons/EcommerceIcon';
import SecurityIcon from '../../assets/icons/SecurityIcon';
import TelcoIcon from '../../assets/icons/TelcoIcon';
import ScienceIcon from '../../assets/icons/ScienceIcon';
import EntertainmentIcon from '../../assets/icons/EntertaimentIcon';
import BigDataIcon from '../../assets/icons/BigDataIcon';
import AIIcon from '../../assets/icons/AIIcon';
import namedropMockup from '../../assets/images/namedrop-mockup.png';
import * as paths from '../../paths';
import styles from './BuildWithExperienceSection.module.scss';

const messages = defineMessages({
  mainTitle: {
    id: 'buildWithExperienceSection.mainTitle',
    defaultMessage: 'Build With Experience'
  },
  title1: {
    id: 'buildWithExperienceSection.title1',
    defaultMessage: 'The Tech'
  },
  description1: {
    id: 'buildWithExperienceSection.description1',
    defaultMessage:
      'These are the main ingredients we use to cook up complex software for our partners. Check out how we put them into action in our customer stories.'
  },
  title2: {
    id: 'buildWithExperienceSection.title2',
    defaultMessage: 'The Verticals'
  },
  description2: {
    id: 'buildWithExperienceSection.description2',
    defaultMessage:
      'Our developers have worked extensively in highly regulated, complex and security sensitive fields. Tell us your industry vertical and we’ll hand-pick a team to fit your needs.'
  },
  button: {
    id: 'buildWithExperienceSection.button',
    defaultMessage: 'Customer Stories'
  }
});

function BuildWithExperienceSection() {
  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>
        <FormattedMessage {...messages.mainTitle} />
      </div>
      <Container className={styles.contentContainer}>
        <div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title1} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description1} />
          </div>
          <div className={styles.button}>
            <Link variant="contained" color="primary" to={paths.projectsPage()}>
              <FormattedMessage {...messages.button} />
            </Link>
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.image}>
              <img src={namedropMockup} alt="namedrop" />
            </div>
            <div className={styles.techContainer}>
              <div className={styles.techContent}>
                <div className={styles.icon}>
                  <ReactIcon />
                </div>
                <div className={styles.description}>React</div>
              </div>
              <div className={styles.techContent}>
                <div className={styles.icon}>
                  <JavaIcon />
                </div>
                <div className={styles.description}>Java</div>
              </div>
              <div className={styles.techContent}>
                <div className={styles.icon}>
                  <NodejsIcon />
                </div>
                <div className={styles.description}>NodeJS</div>
              </div>
              <div className={styles.techContent}>
                <div className={styles.icon}>
                  <ReactIcon />
                </div>
                <div className={styles.description}>React Native</div>
              </div>
              <div className={styles.techContent}>
                <div className={styles.icon}>
                  <CloudIcon />
                </div>
                <div className={styles.description}>Cloud DevOPs</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.title}>
            <FormattedMessage {...messages.title2} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.description2} />
          </div>
          <div className={styles.iconsContainer}>
            <div>
              <div className={styles.iconContainer}>
                <LogisticIcon />
              </div>
              <div className={styles.text}>Logistics</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <FintechIcon />
              </div>
              <div className={styles.text}>Fintech</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <EcommerceIcon />
              </div>
              <div className={styles.text}>E-commerce</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <SecurityIcon />
              </div>
              <div className={styles.text}>Security</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <TelcoIcon />
              </div>
              <div className={styles.text}>Telco</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <ScienceIcon />
              </div>
              <div className={styles.text}>Science</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <EntertainmentIcon />
              </div>
              <div className={styles.text}>Entertainment</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <BigDataIcon />
              </div>
              <div className={styles.text}>Big Data</div>
            </div>
            <div>
              <div className={styles.iconContainer}>
                <AIIcon />
              </div>
              <div className={styles.text}>Artificial Intelligence</div>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.greenIconLeft}>
        <GreenSquareIcon />
      </div>
      <div className={styles.greenIconRight}>
        <GreenSquareIcon />
      </div>
    </div>
  );
}

export default BuildWithExperienceSection;
