import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isMessageDefinition } from '../../utils/message';
import BlueDotsIcon from '../../assets/icons/BlueDotsIcon';
import styles from './WorkingWithUsSection.module.scss';

function WorkingWithUsSection(props) {
  const { children, description, title, intl } = props;

  const renderDescription = () =>
    isMessageDefinition(description)
      ? intl.formatMessage(description, description.values)
      : description;

  const renderTitle = () =>
    isMessageDefinition(title)
      ? intl.formatMessage(title, title.values)
      : title;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>{renderTitle()}</h1>
        <p className={styles.description}>{renderDescription()}</p>
        {children}
      </div>
      <div className={styles.icon}>
        <BlueDotsIcon />
      </div>
    </div>
  );
}

WorkingWithUsSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  children: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

WorkingWithUsSection.defaultProps = {
  title: null,
  description: null,
  children: null
};

export default injectIntl(WorkingWithUsSection);
