import React from 'react';

function ReactIcon() {
  return (
    <svg
      width="31px"
      height="28px"
      viewBox="0 0 31 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-408.000000, -2881.000000)" fill="#00D8FF">
          <g transform="translate(80.000000, 2618.000000)">
            <g transform="translate(328.000000, 263.000000)">
              <ellipse cx="15.5" cy="13.9497006" rx="2.76412078" ry="2.80558882" />
              <path
                d="M15.5,20.3768463 C11.6181172,20.3768463 8.22628774,19.9129741 5.68792185,19.0299401 C4.04156306,18.4598802 2.64298401,17.6998004 1.64635879,16.8335329 C0.589165187,15.9169661 0.0275310835,14.9165669 0.0275310835,13.9497006 C0.0275310835,12.0942116 2.03179396,10.2778443 5.39609236,9.09301397 C8.14920071,8.12055888 11.739254,7.57844311 15.4944938,7.57844311 C19.183659,7.57844311 22.7241563,8.10379242 25.460746,9.06506986 C27.0630551,9.6239521 28.4120782,10.350499 29.3701599,11.1552894 C30.4108348,12.0383234 30.9614565,13.0051896 30.9614565,13.9497006 C30.9614565,15.8778443 28.7204263,17.8283433 25.1083481,19.0411178 C22.5534636,19.9017964 19.1396092,20.3768463 15.5,20.3768463 Z M15.5,8.91976048 C11.9374778,8.91976048 8.41349911,9.44510978 5.8365897,10.3560878 C2.74209591,11.451497 1.35452931,12.9493014 1.35452931,13.9497006 C1.35452931,14.9892216 2.84671403,16.6267465 6.11740675,17.7612774 C8.51811723,18.594012 11.7667851,19.0355289 15.5,19.0355289 C19.0019538,19.0355289 22.2671403,18.5884232 24.6953819,17.7668663 C28.0927176,16.6211577 29.6454707,14.9836327 29.6454707,13.9497006 C29.6454707,13.4187625 29.2490231,12.7928144 28.5277087,12.1836327 C27.69627,11.4794411 26.4849023,10.8367265 25.0367673,10.3281437 C22.4323268,9.42275449 19.0460036,8.91976048 15.5,8.91976048 Z"
                fillRule="nonzero" />
              <path
                d="M9.22841918,27.9329341 C8.66678508,27.9329341 8.17122558,27.80998 7.75275311,27.5640719 C6.17246892,26.6363273 5.62184725,23.9648703 6.28809947,20.4159681 C6.83321492,17.5041916 8.16571936,14.0838323 10.0433393,10.7808383 C11.8879218,7.53932136 14.101421,4.68902196 16.287389,2.76087824 C17.5648313,1.63193613 18.8587922,0.804790418 20.0261101,0.368862275 C21.2980462,-0.106187626 22.3992895,-0.106187626 23.2031972,0.363273452 C24.849556,1.3245509 25.3946714,4.2754491 24.6238011,8.05349301 C24.0786856,10.7361277 22.7792185,13.9720559 20.962167,17.1744511 C19.0239787,20.5836327 16.9316163,23.3333333 14.9108348,25.1273453 C13.6003552,26.2898204 12.2513321,27.1393214 11.0179396,27.5808383 C10.3737123,27.8155689 9.76802842,27.9329341 9.22841918,27.9329341 Z M10.6104796,11.1161677 L11.1831261,11.451497 C9.40461812,14.5812375 8.08863233,17.9401198 7.58206039,20.6674651 C6.97087034,23.9369261 7.56003552,25.9041916 8.4079929,26.4015968 C8.61722913,26.5245509 8.89253996,26.5916168 9.22841918,26.5916168 C10.3241563,26.5916168 12.0476021,25.8874251 14.0408526,24.1213573 C15.9515098,22.4279441 17.9502664,19.79002 19.8168739,16.5093812 C21.5678508,13.4299401 22.8122558,10.3337325 23.3298401,7.79081836 C24.0511545,4.23073852 23.428952,2.0510978 22.5424512,1.53133732 C22.0909414,1.26866267 21.3586146,1.30219561 20.4776199,1.63193613 C19.4589698,2.01197605 18.3081705,2.75528942 17.151865,3.77804391 C15.0760213,5.61117764 12.9561279,8.33852295 11.1831261,11.4570858 L10.6104796,11.1161677 Z"
                fillRule="nonzero" />
              <path
                d="M21.7715808,27.9497006 C20.2738899,27.9497006 18.3742451,27.0331337 16.3920071,25.3005988 C14.178508,23.3668663 11.9264654,20.4886228 10.0433393,17.1856287 C8.19325044,13.9441118 6.87175844,10.5740519 6.31563055,7.68463074 C5.99076377,5.99680639 5.93019538,4.44870259 6.13943162,3.20239521 C6.37069272,1.84431138 6.91580817,0.877445109 7.72522202,0.402395209 C9.3660746,-0.564471059 12.1577265,0.430339321 14.9989343,2.99560878 C17.0142096,4.81197605 19.1285968,7.57285429 20.9511545,10.7696607 C22.894849,14.1788423 24.1998224,17.3924152 24.7174067,20.0638723 C25.053286,21.7964072 25.1083481,23.405988 24.8715808,24.7137725 C24.6182948,26.1053892 24.0456483,27.0946108 23.2197158,27.5808383 C22.8067496,27.8267465 22.3166963,27.9497006 21.7715808,27.9497006 Z M11.1886323,16.5149701 C12.9726465,19.6447106 15.1861456,22.4782435 17.2564831,24.2834331 C19.7397869,26.4518962 21.7110124,26.9213573 22.564476,26.4183633 C23.4509769,25.8986028 24.1007105,23.7692615 23.428952,20.3265469 C22.9333925,17.8003992 21.6834813,14.7265469 19.8113677,11.4459082 C18.0548845,8.36646707 16.034103,5.72295409 14.1234458,4.00159681 C11.4474245,1.58722555 9.27797513,1.04510978 8.39147425,1.56487026 C7.93996448,1.82754491 7.60408526,2.49261477 7.44440497,3.43153693 C7.26269982,4.51576846 7.31776199,5.90179641 7.61509769,7.42754491 C8.14369449,10.1716567 9.41012433,13.3964072 11.1886323,16.5149701 Z"
                fillRule="nonzero" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}

export default ReactIcon;