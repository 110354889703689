import React from 'react';

function TelcoIcon() {
  return (
    <svg
      width="29px"
      height="44px"
      viewBox="0 0 29 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1040.000000, -2973.000000)" stroke="#373737" strokeWidth="1.27999992">
          <g transform="translate(1041.000000, 2974.000000)">
            <rect x="0" y="0" width="27" height="42" />
            <rect x="0" y="0" width="27" height="5.54716981" />
            <ellipse cx="13.5" cy="37.245283" rx="2.38235294" ry="2.37735849" />
            <path d="M10.3235294,2.77358491 L15.8823529,2.77358491" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TelcoIcon;