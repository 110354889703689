import React from 'react';

function BigDataIcon() {
return (
  <svg
    width="44px"
    height="50px"
    viewBox="0 0 44 50"
    xmlns="http://www.w3.org/2000/svg"
  >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1037.000000, -3103.000000)" stroke="#373737">
              <g transform="translate(1038.000000, 3105.000000)">
                  <g transform="translate(1.000000, 0.000000)" strokeWidth="2.08000011">
                      <path d="M31.1796661,9.85926867 C31.053611,4.39284539 26.6383937,0 21.2061688,0 C17.435301,0 14.1538912,2.11696865 12.4567368,5.24001987 C11.6900801,4.7941793 10.8020342,4.53789838 9.85424983,4.53789838 C7.09595328,4.53789838 4.83774665,6.69836744 4.63803194,9.43796482 C1.91698589,10.5685112 0,13.2752898 0,16.4363458 C0,20.6136242 3.34634968,24 7.47442452,24 L29.9593488,24 C33.8477813,24 37,20.8101501 37,16.8752975 C37,13.3616714 34.4860882,10.444357 31.1796661,9.85926867 Z"/>
                  </g>
                  <polyline strokeWidth="1.6" points="11 23 11 31 2 31"/>
                  <polyline strokeWidth="1.6" transform="translate(35.500000, 27.000000) scale(-1, 1) translate(-35.500000, -27.000000) " points="40 23 40 31 31 31"/>
                  <polyline strokeWidth="1.6" points="16 23 16 39 9 39"/>
                  <path d="M21.5,23 L21.5,44" strokeWidth="1.6"/>
                  <polyline strokeWidth="1.6" transform="translate(29.500000, 31.000000) scale(-1, 1) translate(-29.500000, -31.000000) " points="33 23 33 39 26 39"/>
                  <circle strokeWidth="1.6" fill="#FFFFFF" cx="2" cy="31" r="2"/>
                  <circle strokeWidth="1.6" fill="#FFFFFF" cx="40" cy="31" r="2"/>
                  <circle strokeWidth="1.6" fill="#FFFFFF" cx="9" cy="39" r="2"/>
                  <circle strokeWidth="1.6" fill="#FFFFFF" cx="33" cy="39" r="2"/>
                  <circle strokeWidth="1.6" fill="#FFFFFF" cx="21" cy="45" r="2"/>
              </g>
          </g>
      </g>
  </svg>
);
}

export default BigDataIcon;