import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { isMessageDefinition } from '../../utils/message';
import QuoteIcon from '../../assets/icons/QuoteIcon';
import styles from './QuotationSection.module.scss';

function Quotation(props) {
  const {
    description,
    avatar,
    authorName,
    jobTitle,
    children,
    reverse,
    intl
  } = props;

  const renderDescription = () =>
    isMessageDefinition(description)
      ? intl.formatMessage(description, description.values)
      : description;

  return (
    <div
      className={cn(styles.contentContainer, {
        [styles.reverse]: reverse
      })}
    >
      <div className={styles.authorContainer}>
        <div className={styles.avatar}>{avatar}</div>
        <div className={styles.authorTextContainer}>
          <div className={styles.authorName}>{authorName}</div>
          <div className={styles.jobTitle}>{jobTitle}</div>
        </div>
      </div>
      <div className={styles.quoteContainer}>
        <div className={styles.quoteIcon}>
          <QuoteIcon fill={reverse ? '#172849' : '#3AE1AC'} />
        </div>
        <p className={styles.description}>
          {renderDescription()}
          <QuoteIcon className={styles.quoteIconRight} />
        </p>
      </div>
      <div className={styles.button}>{children}</div>
    </div>
  );
}

Quotation.propTypes = {
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  avatar: PropTypes.node,
  authorName: PropTypes.string,
  jobTitle: PropTypes.string,
  children: PropTypes.node,
  reverse: PropTypes.bool
};

Quotation.defaultProps = {
  description: null,
  avatar: null,
  authorName: '',
  jobTitle: '',
  children: null,
  reverse: false
};

export default injectIntl(Quotation);
