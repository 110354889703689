import React from 'react';

function EcommerceIcon() {
  return (
    <svg
      width="56px"
      height="43px"
      viewBox="0 0 56 43"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1217.000000, -2835.000000)" stroke="#373737" strokeWidth="1.43999996">
          <g
            transform="translate(1245.930718, 2848.930718) rotate(-315.000000) translate(-1245.930718, -2848.930718) translate(1234.430718, 2820.930718)">
            <polygon
              points="1.1360704e-13 24.5393258 6.80374624 16.3595506 16.99333 16.3595506 23 24.5393258 23 52.2247191 1.1360704e-13 52.2247191" />
            <rect x="0" y="52.2247191" width="23" height="1.88764045" />
            <rect x="0" y="54.1123596" width="23" height="1.88764045" />
            <ellipse cx="11.8108108" cy="24.5393258" rx="3.10810811" ry="3.14606742" />
            <path d="M12.4265813,24.3674121 C5.99078649,11.9024353 22.2633228,9.61112215 20.2682953,0.611739031"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EcommerceIcon;